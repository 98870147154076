import {
  Box,
  Text,
  Spinner,
  Switch
} from '@chakra-ui/react';

export type LocationFetchToggleButtonProps = {
  isLocationSwitchOn: boolean;
  isLocationLoading: boolean;
  handleCurrentLocationToggle: () => void;
};

export const LocationFetchToggleButton = ({
  isLocationSwitchOn,
  isLocationLoading,
  handleCurrentLocationToggle
}:LocationFetchToggleButtonProps)  => {
  return(
    <Box
      display={'inline-flex'}
      px={2}
    >
      {isLocationSwitchOn ? (
        <Text>現在地情報 ON</Text>
      ):(
        <Text>現在地情報 OFF</Text>
      )}
      {isLocationLoading ? (
        <Spinner
          size={'sm'}
          color={'blue.500'}
          thickness={'4px'}
          speed={'0.65s'}
          emptyColor={'gray.200'}
          ml={4}
        />
        ) : (
        <Switch
          onChange={handleCurrentLocationToggle}
          isChecked={isLocationSwitchOn}
          pl={2}
        />
      )}
    </Box>
  );
};
