import {
  Box,
  Text
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import React, { memo } from 'react';
import {
  Control,
} from 'react-hook-form';
import { ShopSearchSpinner } from '../../../Sp/ModalParts/ShopSearchSpinner';
import {
  ConditionBox,
  ConditionBoxProps
} from '../ConditionBox';
import { ConditionTitle } from '../ConditionTitle';
import { Image } from '@/components/Image';
import { RailroadLineItem } from '@/features/shops/components/ui/ShopSearch/Pc/ModalParts/Section/RailroadLineParts/RailroadLinesItem';
import { ShopSearchConditions } from '@/features/shops/types/shopSearch';
import { ShopSearchParams } from '@/types/shopSearch';

export type RailroadLinesSectionProps = {
  isLoading: boolean;
  control: Control<ShopSearchParams>;
  railroadLines: ShopSearchConditions['railroadLines'];
  onChangeRailroadLine(railroadLineId: number, checked: boolean): void;
} & Omit<ConditionBoxProps, 'children'>;

const NonMemoRailroadLinesSection = ({
  isLoading,
  control,
  railroadLines,
  onChangeRailroadLine,
  ...rest
}: RailroadLinesSectionProps) => {

  return (
    <ConditionBox
      {...rest}
    >
      <ConditionTitle>
        <Image
          src={'/images/search_icon/railroad_icon.png'}
          alt={'駅で絞り込む'}
          width={'23px'}
          height={'10px'}
        />
        <Text
          as={'h2'}
          pl={2}
        >
          沿線・駅
        </Text>
      </ConditionTitle>
      <Box
        maxHeight={'400px'}
        overflowY={'scroll'}
        scrollBehavior={'smooth'}
        css={scrollBoxStyles}
      >
        {isLoading ? (
          <ShopSearchSpinner
            searchTitle={'沿線・駅'}
          />
        ) : (
          <Box
            display={'flex'}
            cursor={'pointer'}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            {railroadLines.map((railroadLine) => {
              return (
                <RailroadLineItem
                  key={railroadLine.id}
                  control={control}
                  railroadLine={railroadLine}
                  onChangeRailroadLine={onChangeRailroadLine}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </ConditionBox>
  );
};

const scrollBoxStyles = css`
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D6E8AC;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }

  -webkit-overflow-scrolling: touch;
`;

export const RailroadLinesSection = memo(NonMemoRailroadLinesSection);
