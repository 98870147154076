import { Text } from '@chakra-ui/react';
import { memo } from 'react';
import { Control } from 'react-hook-form';
import { ConditionBox } from '../ConditionBox';
import { ConditionTitle } from '../ConditionTitle';
import { checkboxStyleProps } from '../SearchForm';
import { CheckboxLabel } from './CheckboxLabel';
import { Image } from '@/components/Image';
import { ControlledCheckbox } from '@/components/Input/Controlled/ControlledCheckbox';
import { ShopSearchConditions } from '@/features/shops/types/shopSearch';
import { ShopSearchParams } from '@/types/shopSearch';

export type ParticularConditionSectionProps = {
  control: Control<ShopSearchParams>;
  particularConditions: ShopSearchConditions['particularConditions'];
};

const NonMemoParticularConditionSection = ({
  control,
  particularConditions
}: ParticularConditionSectionProps) => {
  return (
    <ConditionBox>
      <ConditionTitle>
        <Image
          src={'/images/search_icon/particular_condition_icon.png'}
          alt={'こだわりで絞り込む'}
          width={'23px'}
          height={'10px'}
        />
        <Text
          as={'h2'}
          pl={2}
        >
          こだわり
        </Text>
      </ConditionTitle>
      {particularConditions.map(({ name, id }) => (
        <ControlledCheckbox<ShopSearchParams>
          key={id}
          {...checkboxStyleProps}
          name={'particularConditionIds'}
          control={control}
          value={id}
        >
          <CheckboxLabel>
            {name}
          </CheckboxLabel>
        </ControlledCheckbox>
      ))}
    </ConditionBox>
  );
};

export const ParticularConditionSection = memo(NonMemoParticularConditionSection);
