import { SearchIcon } from '@chakra-ui/icons';
import {
  forwardRef,
  Spinner,
  Text,
} from '@chakra-ui/react';
import {
  SubmitButton,
  SubmitButtonProps
} from '@/components';

type SearchButtonProps = {
  countOfShops: number | null;
  isSearching: boolean;
  isLoadingCountOfShops: boolean;
  isLocationLoading?: boolean;
} & SubmitButtonProps;

export const SearchButton = forwardRef(({
  countOfShops,
  isSearching,
  isLoadingCountOfShops,
  isLocationLoading,
  ...rest
}: SearchButtonProps, ref) => {
  return (
    <SubmitButton
      ref={ref}
      type={'submit'}
      w={'90%'}
      size={'lg'}
      isDisabled={isLocationLoading}
      isLoading={isSearching}
      loadingText={'検索中...'}
      {...rest}
    >
      {countOfShops || countOfShops === 0 ? (
        <>
          {`${countOfShops}店舗を検索する`}
        </>
      ) : (
        <>
          {isLoadingCountOfShops ? (
            <>
              <Spinner
                color={'brand.300'}
                mr={2}
                size={'md'}
                speed={'0.7s'}
              />
              <Text
                color={'brand.300'}
                textStyle={'h4'}
              >
                件数を取得中...
              </Text>
            </>
          ) : (
            <>
              <SearchIcon mr={5}/>
              検索する
            </>
          )}
        </>
      )}
    </SubmitButton>
  );
});
SearchButton.displayName = 'SearchButton';
