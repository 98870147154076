import {
  Box,
  BoxProps,
} from '@chakra-ui/react';
import { memo } from 'react';
import {
  Control,
} from 'react-hook-form';
import { ShopSearchListItemWithCheckbox } from '@/features/shops/components/ui/ShopSearch/Sp/ModalParts/ShopSearchListItemWithCheckbox';
import { ShopSearchConditions } from '@/features/shops/types/shopSearch';
import { ShopSearchParams } from '@/types/shopSearch';

export type RailroadLineItemProps = {
  control: Control<ShopSearchParams>;
  railroadLine: ShopSearchConditions['railroadLines'][number];
  onChangeRailroadLine(railroadLineId: number, checked: boolean): void;
} & BoxProps;

const NonMemoRailroadLineItem = ({
  control,
  railroadLine,
  onChangeRailroadLine,
  ...rest
}: RailroadLineItemProps) => {

  return (
    <Box
      key={railroadLine.id}
      w={'32%'}
      {...rest}
    >
      <ShopSearchListItemWithCheckbox<ShopSearchParams>
        key={railroadLine.id}
        control={control}
        name={'railroadLineIds'}
        formValue={railroadLine.id}
        itemName={railroadLine.name}
        borderRight={'white'}
        width={'full'}
        fontWeight={'bold'}
        minH={'30px'}
        onClick={onChangeRailroadLine}
      >
        {railroadLine.name}
      </ShopSearchListItemWithCheckbox>
      <Box
        padding={0}
        mx={0}
        mb={4}
        mt={2}
        fontWeight={'bold'}
        display={'flex'}
        flexWrap={'wrap'}
      >
        {railroadLine.railroadStations.items.map((station) => {
          return (
            <Box
              textStyle={'h8'}
              _last={{ borderBottom: 'none' }}
              width={'40%'}
              ml={'20px'}
              key={`${railroadLine.id}-${station.id}-${station.name}`}
            >
              <ShopSearchListItemWithCheckbox<ShopSearchParams>
                name={'railroadStationIds'}
                control={control}
                formValue={station.id}
                itemName={station.name}
                p={0}
              >
                {station.name}
              </ShopSearchListItemWithCheckbox>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export const RailroadLineItem = memo(NonMemoRailroadLineItem);
