import { Search2Icon } from '@chakra-ui/icons';
import {
  CheckboxProps,
  Flex,
  Select,
  Text,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { InputWithSearchButton } from '../../InputWithSearchButton';
import { LocationFetchToggleButton } from '../../LocationFetchToggleButton';
import { CitiesSection } from './Section/CitiesSection';
import { CourseGenreSection } from './Section/CourseGenreSection';
import { ParticularConditionSection } from './Section/ParticularConditionSection';
import { RailroadLinesSection } from './Section/RailroadLinesSection';
import { useShopSearch } from '@/features/shops/components/hooks/useShopSearch';
import { ConditionBox } from '@/features/shops/components/ui/ShopSearch/Pc/ModalParts/ConditionBox';
import { ConditionTitle } from '@/features/shops/components/ui/ShopSearch/Pc/ModalParts/ConditionTitle';

type SearchFormProps = {
  shopSearch: ReturnType<typeof useShopSearch>;
  onClose: () => void;
}

export const SearchForm = ({
  shopSearch: {
    isSearching,
    shopSearchForm,
    getShopSearchHandler,
    shopSearchConditions,
    isShopSearchConditionsLoading,
    handleChangeRailroadLineId,
    handleCurrentLocationToggle,
    isLocationSwitchOn,
    isCurrentLocationLoading
  },
  onClose
}: SearchFormProps) => {

  const { control, register, getValues } = shopSearchForm;
  const { allPrefectures, cities, courseGenres, railroadLines, particularConditions } = shopSearchConditions;

  const handleOnSubmit = useMemo(() => {
    return getShopSearchHandler({
      onSuccess: () => {
        onClose();
      }
    });
  }, [getShopSearchHandler, onClose]);

  return (
    <form onSubmit={handleOnSubmit}>
      <ConditionBox>
        <ConditionTitle>
          <Text as={'h2'}>都道府県</Text>
        </ConditionTitle>
        <Flex
          fontWeight={'bold'}
        >
          <Select
            {...register('prefectureId', {
              setValueAs: value => !value && value !== 0 ? null : Number(value),
            })}
            disabled={isLocationSwitchOn || isCurrentLocationLoading ? true : false}
            size={'sm'}
            w={'25%'}
            borderColor={'gray.500'}
            mb={4}
            mr={10}
          >
            <option value={''}>都道府県を選択してください</option>
            {allPrefectures.map(prefecture =>
              <option
                key={prefecture.id}
                value={prefecture.id}
              >
                {prefecture.name}
              </option>
            )}
          </Select>
          <LocationFetchToggleButton
            isLocationSwitchOn={isLocationSwitchOn}
            isLocationLoading={isCurrentLocationLoading}
            handleCurrentLocationToggle ={handleCurrentLocationToggle}
          />
        </Flex>
      </ConditionBox>
      <CitiesSection
        display={getValues('prefectureId') ? 'block' : 'none'}
        control={control}
        isLoading={isShopSearchConditionsLoading}
        cities={cities}
      />
      <RailroadLinesSection
        display={getValues('prefectureId') ? 'block' : 'none'}
        control={control}
        isLoading={isShopSearchConditionsLoading}
        railroadLines={railroadLines}
        onChangeRailroadLine={handleChangeRailroadLineId}
      />
      <CourseGenreSection
        control={control}
        courseGenres={courseGenres}
      />
      <ParticularConditionSection
        control={control}
        particularConditions={particularConditions}
      />

      <ConditionBox
        noBorder={true}
        flex={'1 1 400px'}
        position={'sticky'}
        bg={'white'}
        py={2}
        bottom={0}
      >
        <ConditionTitle>
          <Search2Icon
            mt={1}
            mr={1}
          />
          <Text as={'h2'}>フリーワード検索</Text>
        </ConditionTitle>
        <InputWithSearchButton
          isSearching={isSearching}
          isLocationLoading={isCurrentLocationLoading}
          shopSearchForm={shopSearchForm}
          register={register}
        />
      </ConditionBox>
    </form>
  );
};

export const checkboxStyleProps: CheckboxProps = {
  mr: 4,
  mb: 3,
};
