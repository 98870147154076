import {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  UseFormReturn,
  useWatch
} from 'react-hook-form';
import {
  CountOfSearchedShopsQueryVariables,
  useCountOfSearchedShopsQuery
} from '@/graphql/generated/graphql';
import { ShopSearchParams } from '@/types/shopSearch';

type CountOfShopsProps = {
  form: UseFormReturn<ShopSearchParams>;
}

export const useCountOfShops = ({
  form: { control, getValues }
}: CountOfShopsProps) => {
  const timerId = useRef(0);
  const watchingParams = useWatch({
    name: [
      'prefectureId',
      'cityIds',
      'courseGenreIds',
      'particularConditionIds',
      'railroadLineIds',
      'railroadStationIds',
    ],
    control
  });
  const watchingParamsByDelay = useWatch({
    name: [
      'q',
    ],
    control
  });
  const [searchParams, setSearchParams] = useState<CountOfSearchedShopsQueryVariables>(getValues());

  /**
   * 件数取得
   */
  const {
    data: countOfShopsData,
    isLoading: isLoadingCountOfShops,
  } = useCountOfSearchedShopsQuery(searchParams, {
    cacheTime: 1000 * 10 * 60,
    staleTime: 1000 * 10 * 60,
    enabled: true,
  });

  const countOfShops = useMemo<number | null>(() => {
    return countOfShopsData?.countOfShops.count ?? null;
  }, [countOfShopsData?.countOfShops.count]);

  useEffect(() => {
    setSearchParams(prev => ({
      ...prev,
      prefectureId: watchingParams[0],
      cityIds: watchingParams[1],
      courseGenreIds: watchingParams[2],
      particularConditionIds: watchingParams[3],
      railroadLineIds: watchingParams[4],
      railroadStationIds: watchingParams[5],
    }));
  }, [watchingParams]);

  useEffect(() => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    timerId.current = window.setTimeout(() => {
      setSearchParams(prev => ({
        ...prev,
        q: watchingParamsByDelay[0],
      }));
    }, 700);
  }, [watchingParams, watchingParamsByDelay]);

  return {
    isLoadingCountOfShops,
    countOfShops,
  };
};
