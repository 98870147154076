import {
  Box,
  BoxProps,
  CheckboxProps,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, {
  useCallback
} from 'react';
import {
  ListItem,
  ListItemProps
} from '@/components/ListItem/ListItem';

export interface ShopSearchListItemProps extends Omit<ListItemProps, 'onClick'> {
  isSelected: boolean;
  contentProps?: BoxProps;
  checkboxProps?: CheckboxProps;
  onClick(isSelected: boolean): void;
}

export const ShopSearchListItem = ({
  isSelected,
  onClick,
  children,
  contentProps,
  checkboxProps: _checkboxProps,
  ...props
}: ShopSearchListItemProps) => {
  const handleClick = useCallback(() => {
    onClick(!isSelected);
  }, [isSelected, onClick]);

  const StyledCheckbox = styled.input`
    appearance: none;
    border: 2px solid #cbd5e0;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    margin: 0;
    cursor: pointer;
    position: relative;

    &:checked {
      background-color: #81ae3f;
      border-color: #81ae3f;
    }

    &:checked:after {
      content: '';
      display: block;
      position: absolute;
      top: .2em;
      left: .2em;
      width: 8px;
      height: 5px;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(-45deg);
    }
  `;


  return (
    <ListItem
      bg={isSelected ? { base: 'brand.300', lg: 'white' } : 'white'}
      minH={{ base: '50px', md: '50px', lg: '30px', xl: '30px' }}
      px={0}
      alignItems={'center'}
      {...props}
    >
      <Box
        h={'full'}
        mx={3}
        mt={1}
      >
        <StyledCheckbox
          type={'checkbox'}
          checked={isSelected}
          onChange={handleClick}
        />
      </Box>
      <Box
        w={'full'}
        h={'full'}
        display={'flex'}
        alignItems={'center'}
        onClick={handleClick}
        {...contentProps}
      >
        {children}
      </Box>
    </ListItem>
  );
};
