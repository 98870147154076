import { Text } from '@chakra-ui/react';
import { memo } from 'react';
import { Control } from 'react-hook-form';
import { ConditionBox } from '../ConditionBox';
import { ConditionTitle } from '../ConditionTitle';
import { checkboxStyleProps } from '../SearchForm';
import { CheckboxLabel } from './CheckboxLabel';
import { Image } from '@/components/Image';
import { ControlledCheckbox } from '@/components/Input/Controlled/ControlledCheckbox';
import { ShopSearchConditions } from '@/features/shops/types/shopSearch';
import { ShopSearchParams } from '@/types/shopSearch';

export type CourseGenreSectionProps = {
  control: Control<ShopSearchParams>;
  courseGenres: ShopSearchConditions['courseGenres'];
};

const NonMemoCourseGenreSection = ({
  control,
  courseGenres
}: CourseGenreSectionProps) => {
  return (
    <ConditionBox>
      <ConditionTitle>
        <Image
          src={'/images/search_icon/course_icon.png'}
          alt={'コースで絞り込む'}
          width={'23px'}
          height={'10px'}
        />
        <Text
          as={'h2'}
          pl={2}
        >
          コース
        </Text>
      </ConditionTitle>
      {courseGenres.map(({ name, id }) => (
        <ControlledCheckbox<ShopSearchParams>
          key={id}
          {...checkboxStyleProps}
          name={'courseGenreIds'}
          control={control}
          value={id}
        >
          <CheckboxLabel>
            {name}
          </CheckboxLabel>
        </ControlledCheckbox>
      ))}
    </ConditionBox>
  );
};

export const CourseGenreSection = memo(NonMemoCourseGenreSection);
