import {
  Flex
} from '@chakra-ui/react';
import { ReactNode } from 'react';

type HeaderProps = {
  children: ReactNode;
}

export const ConditionTitle = ({ children }: HeaderProps) => {
  return (
    <Flex
      textStyle={'h5'}
      fontWeight={'bold'}
      color={'brand.500'}
      mt={2}
      mb={4}
    >
      {children}
    </Flex>
  );
};
