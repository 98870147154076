import {
  Box,
  Spinner,
  Text
} from '@chakra-ui/react';

export type ShopSearchSpinnerProps = {
  searchTitle?: string;
  height?: string;
};

export const ShopSearchSpinner = ({
  searchTitle = '店舗',
}: ShopSearchSpinnerProps) => {
  return (
    <Box
      w={'full'}
      textAlign={'center'}
      display={'flex'}
      justifyContent={'center'}
      height={'100px'}
    >
      <Spinner
        mt={5}
        thickness="2px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.500"
        size="lg"
      />
      <Text
        ml={'15px'}
        mt={'28px'}
      >
        {searchTitle}を取得中...
      </Text>
    </Box>
  );
};
