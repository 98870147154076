import {
  Text,
  TextProps
} from '@chakra-ui/react';
import { ReactNode } from 'react';

export type CheckboxLabelProps = {
  children: ReactNode;
} & TextProps;

export const CheckboxLabel = ({ children, ...rest }: CheckboxLabelProps) => {
  return (
    <Text
      textStyle={'h8'}
      fontWeight={'bold'}
      {...rest}
    >
      {children}
    </Text>
  );
};
