import {
  Box,
  BoxProps,
} from '@chakra-ui/react';
import React from 'react';

export type ConditionBoxProps = {
  children: React.ReactNode;
  noBorder?: boolean;
} & BoxProps;

export const ConditionBox = ({
  noBorder = false,
  children,
  ...rest
}: ConditionBoxProps) => {
  return (
    <Box
      mx={3}
      pt={3}
      _first={{ pt: 0 }}
      borderBottom={noBorder ? undefined : '1px dashed'}
      borderColor={noBorder ? undefined : 'gray.300'}
      {...rest}
    >
      {children}
    </Box>
  );
};
