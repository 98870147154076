import {
  Checkbox as OriginalCheckbox,
  CheckboxProps as OriginalCheckboxProps,
  forwardRef,
} from '@chakra-ui/react';

type CheckboxProps = {} & OriginalCheckboxProps;

export const Checkbox = forwardRef(({
  children,
  ...rest
}: CheckboxProps, ref) => {
  return (
    <>
      <OriginalCheckbox
        ref={ref}
        colorScheme={'brand'}
        borderColor={'gray.300'}
        size={'lg'}
        mr={3}
        {...rest}
      >
        {children}
      </OriginalCheckbox>
    </>
  );
});
