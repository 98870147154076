import { Search2Icon } from '@chakra-ui/icons';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Flex,
} from '@chakra-ui/react';
import { memo } from 'react';
import { useShopSearch } from '../../../hooks/useShopSearch';
import { SearchForm } from './ModalParts/SearchForm';

export type PcShopSearchModalProps = {
  shopSearch: ReturnType<typeof useShopSearch>;
  isOpen: boolean;
  onClose: () => void;
}

export const PcShopSearchModal = memo(({
  shopSearch,
  isOpen,
  onClose
}: PcShopSearchModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'6xl'}
      motionPreset={'none'}
    >
      <ModalOverlay/>
      <ModalContent>
        <ModalCloseButton/>
        <ModalBody
          mt={5}
        >
          <Flex
            alignItems={'center'}
            pb={5}
            color={'brand.500'}
          >
            <Search2Icon
              fontSize={20}
            />
            <Text
              as={'h1'}
              textStyle={'h2'}
              pl={2}
            >
              条件変更
            </Text>
          </Flex>
          <SearchForm
            shopSearch={shopSearch}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
PcShopSearchModal.displayName = 'PcShopSearchModal';

