import {
  Control,
  Controller,
  FieldPath,
  FieldPathValue,
  FieldValues
} from 'react-hook-form';
import { ShopSearchListItem } from './ShopSearchListItem';
import {
  ListItemWithCheckboxProps
} from '@/components/ListItem';

interface ShopSearchListItemWithCheckboxProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<ListItemWithCheckboxProps, 'onClick' | 'isSelected'> {
  itemName: string;
  formValue: FieldPathValue<TFieldValues, FieldPath<TFieldValues>>;
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;

  onClick?(formValue: FieldPathValue<TFieldValues, FieldPath<TFieldValues>>, checked: boolean): void;
}

export const ShopSearchListItemWithCheckbox = <TFieldValues extends FieldValues = FieldValues>({
  itemName,
  formValue,
  name,
  control,
  onClick,
  children,
  ...restListItemWithCheckboxProps
}: ShopSearchListItemWithCheckboxProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <ShopSearchListItem
            fontWeight={{ base: 'bold', lg: 'light' }}
            borderTop={'none'}
            borderBottom={{ base: '1px solid #cbd5e0', lg: 'none' }}
            borderRight={{ base: '1px solid #cbd5e0', lg: 'none' }}
            as={'li'}
            aria-label={itemName}
            checkboxProps={{
              'aria-label': itemName,
            }}
            isSelected={value.includes(formValue)}
            cursor={'pointer'}
            onClick={(isSelected) => {
              if (isSelected) {
                onChange([
                  ...value,
                  formValue,
                ]);
              } else {
                onChange(value.filter((v: TFieldValues) => v !== formValue));
              }
              onClick && onClick(formValue, isSelected);
            }}
            {...restListItemWithCheckboxProps}
          >
            {children}
          </ShopSearchListItem>
        );
      }}
    />
  );
};
