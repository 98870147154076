import { InputGroup } from '@chakra-ui/react';
import { memo } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { useCountOfShops } from '../../hooks/useCountOfShops';
import { SearchButton } from './SearchButton';
import { Input } from '@/components';
import { ShopSearchParams } from '@/types/shopSearch';

export type InputWithSearchButtonProps = {
  isSearching: boolean;
  isLocationLoading?: boolean;
  shopSearchForm: UseFormReturn<ShopSearchParams>;
  register: UseFormRegister<ShopSearchParams>;
};

const NonMemoInputWithSearchButton = ({
  isSearching,
  isLocationLoading,
  shopSearchForm,
  register
}: InputWithSearchButtonProps) => {
  const { isLoadingCountOfShops, countOfShops } = useCountOfShops({ form: shopSearchForm });

  return (
    <InputGroup>
      <Input
        placeholder={'地名・駅・店名など'}
        size={'lg'}
        flex={'1 1 400px'}
        borderTopLeftRadius={'106px'}
        borderBottomLeftRadius={'106px'}
        borderColor={'brand.500'}
        {...register('q')}
      />
      <SearchButton
        countOfShops={countOfShops}
        isSearching={isSearching}
        isLocationLoading={isLocationLoading}
        isLoadingCountOfShops={isLoadingCountOfShops}
        borderRadius={'none'}
        borderTopRightRadius={'106px'}
        borderBottomRightRadius={'106px'}
        size={'lg'}
        fontSize={'md'}
        flex={'1 1 280px'}
      />
    </InputGroup>
  );
};
export const InputWithSearchButton = memo(NonMemoInputWithSearchButton);
