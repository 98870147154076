import { CheckboxProps } from '@chakra-ui/react';
import React from 'react';
import {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  Controller
} from 'react-hook-form';
import { Checkbox } from '@/components/Input/Checkbox';


export type ControlledCheckboxProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>;
  value: FieldPathValue<TFieldValues, FieldPath<TFieldValues>>;
  control: Control<TFieldValues>;
  children?: React.ReactNode;
} & CheckboxProps;

export const ControlledCheckbox = <TFieldValues extends FieldValues = FieldValues>({
  name,
  value,
  control,
  children,
  ...rest
}: ControlledCheckboxProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value: formValues, onChange } }) => (
        <>
          <Checkbox
            isChecked={formValues.includes(value)}
            onChange={(e) => {
              if (e.target.checked) {
                return onChange([
                  ...formValues,
                  value,
                ]);
              }
              onChange(formValues.filter((v: TFieldValues) => v !== value));
            }}
            {...rest}
          >
            {children}
          </Checkbox>
        </>
      )}
    />
  );
};
