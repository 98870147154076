import {
  Box,
  HStack,
  StackProps
} from '@chakra-ui/react';
import React from 'react';

export interface ListItemProps extends StackProps {
  children: React.ReactNode;
}

export const borderColor = 'gray.300';

export const ListItem = ({
  children,
  ...props
}: ListItemProps) => {
  return (
    <HStack
      as={'div'}
      w={'full'}
      alignItems={'stretch'}
      spacing={0}
      borderTop={'1px'}
      borderColor={borderColor}
      mt={0}
      minH={50}
      bgColor={'white'}
      px={5}
      {...props}
    >
      <Box
        w={'full'}
        display={'flex'}
        alignItems={'center'}
      >
        {children}
      </Box>
    </HStack>
  );
};
